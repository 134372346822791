import { useState } from 'react';

import { Container } from '@playbooks/interface/grid';
import { AboutWhatSection } from 'components/about/about-what-section';
import { AppToggle } from 'components/app/app-toggle';
import { BountyDisplaySection } from 'components/bounty/bounty-display-section';
import { IndexHero } from 'components/index/index-hero';
import { MktWrapper } from 'components/mkt/mkt-wrapper';
import { RepoDisplaySection } from 'components/repo/repo-display-section';

const IndexRoute = ({ ssr, account, router, session, store, toast }) => {
	const [data, setData] = useState(ssr.popular?.data || {});
	const { content, layout } = router.query;

	// Render
	return (
		<MktWrapper seo={{ title: 'Playbooks | Hybrid Source Software' }}>
			<IndexHero />
			<Container size='xl'>
				{content === 'bounties' ? (
					<BountyDisplaySection
						type={layout || 'display'}
						title={<AppToggle />}
						api={{ view: 'recent' }}
						rootApi={``}
						options={['views', 'layout']}
						views={['alphabetical', 'popular', 'trending', 'recent']}
						pagination={{ type: 'btn' }}
						tailwind={{ section: { border: 'border-b', spacing: 'pt-4 pb-8' }, footer: { display: 'flex-middle' } }}
					/>
				) : (
					<RepoDisplaySection
						type={layout || 'display'}
						title={<AppToggle />}
						api={{ view: 'recent' }}
						rootApi={``}
						options={['views', 'layout']}
						views={['alphabetical', 'popular', 'trending', 'recent']}
						pagination={{ type: 'btn' }}
						tailwind={{ section: { border: 'border-b', spacing: 'pt-4 pb-8' }, footer: { display: 'flex-middle' } }}
					/>
				)}
				<AboutWhatSection />
			</Container>
		</MktWrapper>
	);
};

export default IndexRoute;
