import { useEffect, useMemo, useState } from 'react';
import { useRouter } from 'next/router';

import {
	Section,
	SectionActions,
	SectionBody,
	SectionFooter,
	SectionHeader,
	SectionTitle,
} from '@playbooks/interface/sections';
import { repoParams, serialize } from 'api';
import { RepoDisplayActions } from 'components/repo/repo-display-actions';
import { RepoList } from 'components/repo/repo-list';
import { useStore } from 'contexts';
import { useAction, useQuery } from 'hooks';
import { Pagination } from 'molecules/paginations';

const RepoDisplaySection = ({
	type = 'display',
	title,
	api,
	rootApi = '',
	rootLink = '',
	options,
	views,
	pagination,
	tailwind,
}) => {
	const [repos, setRepos] = useState([]);
	const [params, setParams] = useState<any>({ ...repoParams, ...api });
	const [meta, setMeta] = useState({ page: 0, pageSize: 0, totalRecords: 0 });
	const router = useRouter();
	const store = useStore();

	// Computed
	const computedQuery = useMemo(() => serialize(router.query, ['tier', 'variant', 'view']), [router.query]);

	// Hooks
	useEffect(() => {
		fetchData();
	}, [params, router.query.tier, router.query.variant, router.query.view]);

	// Actions
	const [fetchData, loading] = useQuery(async () => {
		const response = await store.query({ url: `${rootApi}/repos`, params: { ...params, ...computedQuery } });
		setRepos(response.data);
		setMeta(response.meta);
	});

	const [fetchMore, moreTask] = useAction({}, async page => {
		const response = await store.query({ url: `${rootApi}/repos`, params: { ...params, ...computedQuery, page } });
		setRepos(repos.concat(response.data));
		setMeta(response.meta);
	});

	// Render
	return (
		<Section border='' borderRadius='' spacing='py-4' {...tailwind?.section}>
			<SectionHeader
				border=''
				display='lg:flex-between'
				space='space-y-4 lg:space-y-0'
				spacing='py-4'
				{...tailwind?.header}>
				{title ? title : <SectionTitle>{meta.totalRecords || repos.length} Results</SectionTitle>}
				<SectionActions {...tailwind?.actions}>
					<RepoDisplayActions params={params} setParams={setParams} options={options} views={views} />
				</SectionActions>
			</SectionHeader>
			<SectionBody size='lg' spacing='py-4' {...tailwind?.body}>
				<RepoList type={type} repos={repos} loading={loading} rootLink={rootLink} tailwind={tailwind?.list} />
			</SectionBody>
			<SectionFooter border='' spacing='py-4' {...tailwind?.footer}>
				<Pagination
					meta={meta}
					params={params}
					setParams={setParams}
					taskRunning={moreTask.running}
					onClick={fetchMore}
					{...pagination}
				/>
			</SectionFooter>
		</Section>
	);
};

export { RepoDisplaySection };
