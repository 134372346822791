import { useMemo } from 'react';
import { useRouter } from 'next/router';

import { BreadcrumbItem, BreadcrumbLink, Breadcrumbs } from '@playbooks/interface/breadcrumbs';

const AppBreadcrumbs = ({ tailwind }) => {
	const router = useRouter();

	// Computed
	const breadcrumbs = useMemo(() => {
		const paths = router.asPath.split('?')[0].split('#')[0].split('/');
		paths.shift();
		return paths;
	}, [router.asPath]);

	// Render
	return (
		<Breadcrumbs
			border='border-b'
			borderRadius=''
			overflow='overflow-x-scroll'
			spacing='px-4 py-3'
			{...tailwind?.breadcrumbs}>
			<BreadcrumbItem>
				<BreadcrumbLink icon='house' href='/' />
			</BreadcrumbItem>
			{breadcrumbs.map((breadcrumb, i) => (
				<BreadcrumbItem key={i} arrow={breadcrumbs.length !== i + 1}>
					<BreadcrumbLink href={'/' + breadcrumbs.slice(0, i + 1).join('/')} active={breadcrumbs.length === i + 1}>
						{parseInt(breadcrumb) ? `${parseInt(breadcrumb)}` : breadcrumb}
					</BreadcrumbLink>
				</BreadcrumbItem>
			))}
		</Breadcrumbs>
	);
};

export { AppBreadcrumbs };
