import {
	RepoDisplayCard,
	RepoGridCard,
	RepoListCard,
	RepoPillCard,
	RepoPreviewActionCard,
	RepoPreviewCard,
	RepoSelectCard,
	RepoSubmitCard,
} from 'components/repo/repo-card';
import { List } from 'molecules/lists';

const RepoList = ({ type = 'list', icon = 'code', title = 'repos', text = '', repos, ...listProps }) => {
	const props = { type, icon, title, text, ...listProps };

	// Item
	const renderItem = props => {
		switch (type) {
			case 'display':
				return <RepoDisplayCard repo={props.item} {...props} />;

			case 'grid':
				return <RepoGridCard repo={props.item} {...props} />;

			case 'list':
				return <RepoListCard repo={props.item} {...props} />;

			case 'pill':
				return <RepoPillCard repo={props.item} {...props} />;

			case 'preview':
				return <RepoPreviewCard repo={props.item} {...props} />;

			case 'preview-action':
				return <RepoPreviewActionCard repo={props.item} {...props} />;

			case 'select':
				return <RepoSelectCard repo={props.item} {...props} />;

			case 'submit':
				return <RepoSubmitCard repo={props.item} {...props} />;
		}
	};

	// Render
	return <List data={repos} renderItem={renderItem} {...props} />;
};

export { RepoList };
